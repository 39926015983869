$base-scale: 1.64rem;

$grid-gutter: 0.5*$base-scale;
$icon-gutter: 0.45*$grid-gutter;

$unit-one: 8.33333%;
$unit-two: 16.66667%;
$unit-three: 25%;
$unit-four: 33.33333%;
$unit-five: 41.66667%;
$unit-six: 50%;
$unit-seven: 58.33333%;
$unit-eight: 66.66667%;
$unit-nine: 75%;
$unit-ten: 83.33333%;
$unit-eleven: 91.66667%;
$unit-twelve: 100%;

$break-point-tiny: 400px;
$break-point-small: 760px;
$break-point-medium: 1200px;

/* Colors schema---------------------- */
$color-red: #C35258;
$color-orange: orange;
$color-green: #5bbf80;
$color-blue: #3e35ee;

$color-primary: #3e35ee;
$color-primary-hover: darken(#3e35ee, 25%);
$color-primary-pressed: #1E7A9C;

$font-color-primary: #333;
$font-color-secondary: #888;
$font-color-light: #999;

$header-font-color-primary: #1C1B17;
$header-font-color-secondary: #728492;

$color-border: #D8D8D8;

/* Font Settings ---------------------- */
$font-family-base: "Alegreya", Georgia, Times, serif;
$font-family-header: $font-family-base;
$font-family-input: $font-family-base;

$font-size-base: 1em;
$font-size-tiny: 0.7rem;
$font-size-small: 0.85rem;
$font-size-medium: 1rem;
$font-size-big: 1.15rem;
$font-size-huge: 1.3rem;

/* Radiuses ---------------------- */
$radius-small: 0.2rem;
$radius-big: 0.35rem;
$radius-button: $radius-small;

/* Shadows ---------------------- */

$inner-shadow: inset 0 1px 3px rgba(#000, 0.05);
$outer-shadow: 0 1px 3px rgba(#000, 0.05);

/* Mixins ---------------------- */

@mixin font-smoothing($arguments) {
  -webkit-font-smoothing: $arguments;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin text-overflow() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -icab-text-overflow: ellipsis;
  -khtml-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
}

@mixin inner-shadow() {
  box-shadow: $inner-shadow;
}

@mixin outer-shadow() {
  box-shadow: $outer-shadow;
}

@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: image-url($image);
    @include background-size($width $height);
  }
}
